import '../../components/WritingPage.css';

const arabicTranscribers = () => (
  <div id="page">
    <p>
      نحن <strong>النسَّاخون العرب</strong> ننسخ/نعيد كتابة الكتب الممسوحة
      ضوئيًا <span className="textEnglish">(PDF)</span> ليتم تحويلها إلى كتب
      نصية <span className="textEnglish">(EPUB</span> أو{' '}
      <span className="textEnglish">KFX)</span>.
    </p>
    <p>
      هدفنا تنصيص كتاب واحد كل شهر بإذن الله. كلما زاد عددنا زادت مقدرتنا على
      نسخ الكتب. الكتب التي ننسخها عادةً ما يتراوح عدد صفحاتها بين ٢٠٠ صفحة إلى
      ٥٠٠ صفحة.
    </p>
    <p>
      على سبيل المثال، كتاب عدد صفحاته ٣٠٠ صفحة. لو قُسِّمت على ٣٠ شخص فكل شخص
      سيُعيد كتابة ١٠ صفحات. عادةً إعادة كتابة صفحة كاملة يستغرق ٥ دقائق.
      وبالتالي ٥ دقائق للصفحة في ١٠ صفحات يساوي خمسون دقيقة في الشهر{' '}
      <mark>بمعدل ١٢ دقيقة في الأسبوع</mark>
      <span className="emojis">🙂</span>. مع كل زيادة في عددنا سوف يقل حِمل
      الفرد ويقل الوقت المُستغرق في إعادة الكتابة.
    </p>

    <h4>
      <u>أنواع الكتب التي ننسخها</u>:
    </h4>
    <ul className="oneCUl">
      <li>
        الكتب التي لا سبيل لإعادة طباعتها (المؤلف مسجون/متوفى والكتاب ممنوع).
      </li>
      <li>الكتب التي نشرها مؤلفوها.</li>
      <li>الكتب التي تعتبر إرث لأمة الإسلام.</li>
      <li>أي كتاب تواصل معنا مؤلفه لتنصيصة.</li>
    </ul>

    <p>
      حاليًا نعمل على تنصيص كتاب «هكذا ربانا جدي علي الطنطاوي» لحفيدته: عابدة
      المؤيد العظم. بحمد الله تم تنصيص ٣٤ صفحة من أصل ١٣٠ صفحة. ٩٦ صفحة مُتبقية.
    </p>
    <p>
      أي شخص يمكنه الانضمام والمساعدة في تنصيص الكتب لتكون له «عِلْمٍ يُنْتَفَعُ
      بِهِ» يوم القيامة بإذن الله <span className="emojis">🤲</span>
    </p>
    <p>
      نحن نستخدم تطبيق <span className="textEnglish">Telegram</span> للتواصل
      وترتيب العمل. كل شخص يحدد عدد الدقائق التي يمكنه أن يتبرع بها خلال
      الأسبوع.
    </p>
    <p>
      للانضمام اضغط <a href="https://t.me/arabtranscribers">هنا</a> أو ابحث عن{' '}
      <span className="textEnglish">arabtranscribers</span> على تطبيق{' '}
      <span className="textEnglish">Telegram</span>.
    </p>
  </div>
);

export default arabicTranscribers;

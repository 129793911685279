import styled from 'styled-components';
import '../../components/WritingPage.css';
/* ---------------------------------- style --------------------------------- */

/* -------------------------------- component ------------------------------- */
const connectUs = () => (
  <div id="page">
    <p>
      نريد أن نساعد أي مؤلف هدفه نشر الوعي بين الشباب المسلم وخصوصًا الجيل
      الجديد عن طريق نشر كتبه إلكترونيًا ليسهل قراءتها على الأجهزة اللوحية
      والهواتف الذكية.
    </p>
    <p>
      من كان على معرفة بأحد هؤلاء المؤلفين أو دور النشر فليتفضل بتقديمنا له لعل
      أن تكون صدقة جارية.
    </p>
    <article className="listsDiv">
      <section className="listAndTitle">
        <h4>
          <u>المؤلفون</u>
          <sup style={{ fontSize: '70%' }}>*</sup>
        </h4>
        <ul>
          <li>أحمد السيد</li>
          <li>أحمد مولانا</li>
          <li>أحمد موفق زيدان</li>
          <li>أيمن خليل البلوي</li>
          <li>إياد قنيبي</li>
          <li>راغب السرجاني</li>
          <li>سامي عامري</li>
          <li>صهيب محمود السقار</li>
          <li>عبدالله العجيري</li>
          <li>فايز الكندري</li>
          <li>محمد إلهامي</li>
          <li>محمد هنداوي الأزهري</li>
          <li>هشام عزمي</li>
          <li>هيثم طلعت</li>
        </ul>
      </section>
      <section className="listAndTitle">
        <h4>
          <u>المراكز</u>
          <sup style={{ fontSize: '70%' }}>*</sup>
        </h4>
        <ul style={{ columnCount: '2', columnGap: '60px' }}>
          <li>تكوين</li>
          <li>دلائل</li>
          <li>براهين</li>
          <li>بينات</li>
          <li>تبصير</li>
          <li>رواسخ</li>
        </ul>
      </section>
    </article>
    <p>
      بعض هؤلاء المؤلفين قد نشر كُتبه بالفعل دون مقابل — جزاهم الله خيرًا — وسبب
      وجود أسمائهم في القائمة هو لطلب الملف النصي{' '}
      <span className="textEnglish">(Word)</span> منهم أو من دور نشرهم لكي يسهُل
      علينا تحويله إلى كتاب إلكتروني.
    </p>
    <p>
      أما من كانت كتبهم غير متاحة فنرجوا أن يتم التواصل بيننا لأخذ إذنهم بتنصيص
      ونشر كتبهم سواء أرادوا نشرها بدون مقابل أم بمقابل — ولهم كل الربح على غير
      عادة دور النشر التي تأخذ نسبة.
    </p>
    <p>
      لقد أرسلنا لأكثر من مؤلف على موقع{' '}
      <span className="textEnglish">Twitter</span> ولكن للأسف لم يصلنا رد بعد.
    </p>
    <p>
      للتواصل معنا على
      <span className="textEnglish">Twitter </span>اضغط{' '}
      <a href="https://twitter.com/arabicbookorg">هنا</a>.
    </p>
    <ul style={{ listStyle: 'none', fontSize: '70%' }} className="notes">
      <li>
        <sup>*</sup> قائمة الأسماء ليست لحصر المؤلفين أو المراكز ولكن كبداية
        طريق.
      </li>
      <li>
        <sup>*</sup> اعذرونا على حذف الألقاب.
      </li>
    </ul>
  </div>
);

export default connectUs;

import twitter from '../assets/socialIcons/twitter.svg';
import telegram from '../assets/socialIcons/telegram.svg';

const Icon = ({ iconName }) => {
  let link = '';
  let source = '';
  switch (iconName) {
    case 'twitter':
      link = 'https://twitter.com/arabicbookorg';
      source = twitter;
      break;
    case 'telegram':
      link = 'https://t.me/arabtranscribers';
      source = telegram;
      break;
    default:
      break;
  }
  return (
    <>
      <a href={link} className="imgContainer">
        <img src={source} alt={(iconName, 'icon')} className="socialImg" />
      </a>
    </>
  );
};

export default Icon;

import styled from 'styled-components';
import Icon from './Icon';

/* ---------------------------------- style --------------------------------- */
const FooterBar = styled.div`
  align-items: center;
  background-color: #29252f;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  margin: 0px;
  max-height: 80px;
  min-height: 80px;
  padding: 0;
  text-align: center;
  width: 100%;
  & .imgContainer {
    height: 25px;
    width: 25px;
  }
  & .socialImg {
    height: 100%;
  }
  @media (max-width: 801px) {
    min-height: 70px;
  }
`;

/* -------------------------------- component ------------------------------- */
const Footer = () => (
  <FooterBar>
    <Icon iconName="twitter" />
    <Icon iconName="telegram" />
  </FooterBar>
);

export default Footer;

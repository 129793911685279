import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import arabicTranscribers from './features/arabicTranscribers/arabicTranscribers';
import connectUs from './features/connectUs/connectUs';
import Footer from './components/Footer';
import Soon from './components/Soon';
import BookList from './features/books/BookList';
import Header from './features/header/Header';

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route
          exact
          path="/arabictranscribers"
          component={arabicTranscribers}
        />
        <Route exact path="/connectus" component={connectUs} />
        <Route exact path="/soon" component={Soon} />
        <Route path="/" component={BookList} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;

import { Link } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import styled from 'styled-components';

/* ---------------------------------- style --------------------------------- */
const MenuDiv = styled.div`
  margin: 0px auto;
  border-top: 1px solid #d4d4d4;
  border-bottom: 4px double #000000;
  text-align: center;
  width: 100%;
  max-width: 1200px;
`;
const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  max-width: 800px;
  margin: auto;
  text-align: center;
  font-size: 1.1rem;
  a {
    text-decoration: none;
    width: fit-content;
    padding: 5px 5px 0px 5px;
    border-radius: 8px;
    text-align: center;
    color: #4f1b32;
    :active,
    :hover {
      text-decoration: underline;
      color: #234099;
    }

    @media (max-width: 801px) {
      font-size: 0.7em;
    }
  }
`;
/* -------------------------------- component ------------------------------- */
const Menubar = () => {
  const menuTitles = [
    { name: 'كتب', path: '/books' },
    { name: 'مقالات', path: '/soon' },
    { name: 'صِلنا بمؤلف', path: '/connectus' },
    // { name: 'أُنشر معنا', path: '/soon' },
    // { name: 'من نحن', path: '/' },
    { name: 'النسَّاخون العرب', path: '/arabictranscribers' },
  ];
  const menuItems = menuTitles.map((item) => (
    <Link key={nanoid()} to={item.path}>
      {item.name}
    </Link>
  ));
  return (
    <MenuDiv>
      <Menu>{menuItems}</Menu>
    </MenuDiv>
  );
};

export default Menubar;

import styled from 'styled-components';
import bookList from '../../assets/bookList.json';
import MoreComing from '../../components/MoreComing';
import cover from '../../assets/bookCovers/144200101.jpg';

const BookDisplay = styled.article`
  margin: 80px auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex-grow: 2;
`;
const BookCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 230px;
  padding-bottom: 5px;
  /* border-bottom: 0.5px solid #84242e; */
  text-decoration: none;
  & .rthDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    height: 100%;
  }
  & .bookInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 12px;
    & > * {
      margin: 0;
    }
  }
  & .bookTitle {
    color: black;
    width: 130px;
  }
  & .bookAuthor {
    color: #28252e;
  }
  & .purchaseButton {
    text-align: center;
    margin-bottom: 20px;
    text-decoration: none;
    color: #f8f4ee;
    border: 1px solid #711322;
    background-color: #711322;
    border-radius: 8px;
    padding: 4px 10px 0px 10px;
    width: fit-content;
    line-height: 35px;
    vertical-align: bottom;
    :hover {
      background-color: #f8f4ee;
      color: #711322;
    }
  }
  & .bookCoverContainer {
    height: 160px;
  }
  & .bookCover {
    height: 100%;
    border-radius: 8px;
  }
`;

const BookList = () => {
  const books = bookList.map((book) => (
    <BookCard key={book.id}>
      <section className="rthDetails">
        <section className="bookInfo">
          <h5 className="bookTitle">{book.title}</h5>
          <p className="bookAuthor">{book.author}</p>
        </section>
        <a
          href="https://leanpub.com/maalemfiltareeq"
          className="purchaseButton"
        >
          اقتن الكتاب
        </a>
      </section>
      <section className="bookCoverContainer">
        <img src={cover} alt={`${book.title}`} className="bookCover" />
      </section>
    </BookCard>
  ));
  return (
    <BookDisplay>
      {books}
      {/* <MoreComing /> */}
    </BookDisplay>
  );
};

export default BookList;

import styled from 'styled-components';
import Menubar from './Menubar';
/* ---------------------------------- style --------------------------------- */
const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px 0 5px 0;
  padding: 0px 3%;
  @media (max-width: 510px) {
    padding: 0px 2%;
  }
`;

const Navbar = styled.nav`
  display: grid;
  grid-template-columns: 1fr 0.5fr 2fr 0.5fr 1fr;
  grid-template-rows: 0.2fr 1fr;
  grid-template-areas:
    'basmallah basmallah basmallah basmallah basmallah'
    'date title title title lang';
  text-align: center;
  height: fit-content;
  margin-bottom: 5px;
  padding: 0;
  max-width: 1200px;
  width: 100%;
  padding: 0;
  justify-content: stretch;
  align-items: center;
  & .basmallah {
    font-size: 0.85em;
    font-weight: 200;
    max-height: fit-content;
    grid-area: basmallah;
    @media (max-width: 510px) {
      font-size: 0.65em;
    }
  }
  & .title {
    grid-area: title;
    font-size: 3.1em;
    font-weight: 900;
    justify-self: center;
    @media (max-width: 810px) {
      font-size: 2.5em;
    }
    @media (max-width: 630px) {
      font-size: 2em;
    }
    @media (max-width: 510px) {
      font-size: 1.3em;
    }
  }
  & .info {
    font-size: 0.9em;
    font-weight: 800;
    @media (max-width: 810px) {
      font-size: 0.9em;
    }
    @media (max-width: 510px) {
      font-size: 0.8em;
    }
    @media (max-width: 450px) {
    }
  }
  & .infoDate {
    grid-area: date;
    justify-self: right;
  }
  & .infoLang {
    grid-area: lang;
    justify-self: left;
  }
  & * {
    margin: 0;
    padding: 0;
  }
`;

/* -------------------------------- component ------------------------------- */
const Header = () => {
  const localOptions = {
    calendar: 'islamic',
    dateStyle: 'long',
    numberingSystem: 'arab',
  };
  const todaysDate = new Date();
  const hijriDate = new Intl.DateTimeFormat('ar', localOptions).format(
    todaysDate,
  );
  return (
    <PageHeader>
      <Navbar>
        <h1 className="basmallah">﷽</h1>
        <p className="info infoLang">اللغة العربية</p>
        <h1 className="title">مؤسسة الكتاب العربي</h1>
        <p className="info infoDate">{hijriDate}</p>
      </Navbar>
      <Menubar />
    </PageHeader>
  );
};

export default Header;

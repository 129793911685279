import styled from 'styled-components';

const MoreComing = styled.div`
  margin: auto;
  text-align: center;
  margin-top: 40px;
  & > p {
    font-size: 30px;
  }
`;
const Soon = () => (
  <MoreComing>
    <p>إن شاء الله يتوافر المزيد قريبًا</p>
  </MoreComing>
);

export default Soon;
